<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    viewBox="0 0 24 24"
    width="512"
    height="512"
  >
    <path
      d="M23.57,17.55l-3.3,3.3-.71-.71,3.14-3.14H13v-1h9.7l-3.14-3.14,.71-.71,3.3,3.3c.58,.58,.58,1.52,0,2.11ZM1,23H20v1H0V2.5C0,1.12,1.12,0,2.5,0H12.71l7.29,7.29v2.71h-1v-2h-7V1H2.5c-.83,0-1.5,.67-1.5,1.5V23ZM13,7h5.29L13,1.71V7Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'transfertFile'
};
</script>

<style lang="scss" scoped>
svg {
  fill: #704ad1;
  overflow: hidden;
  vertical-align: middle;
  transform: rotateZ(0deg);
  height: 25px;
  width: 25px;
}
</style>
